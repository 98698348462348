import { React, useEffect } from 'react';
import { bool, object, any } from 'prop-types';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { Row, Col } from 'react-flexbox-grid';
// import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as reduxActions from './redux/actions';
import './App.scss';
import Header from './components/header/header';
import Loading from './components/loading/loading';
import Home from './containers/home/home';
import Property from './components/property';
import NotFound from './components/notFound/notFound';
import TypeForm from './containers/typeForm/typeForm';

const App = ({ generalLoading, user, actions }) => {
  useEffect(() => {
    const userId = window.location.pathname.split('/');
    if (userId[1] !== '' && userId[1] !== '*' && userId[1] !== 'funnel')
      actions.getUser(userId[1]);
  }, []);

  return (
    <>
      {generalLoading && <Loading />}
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                {' '}
                <Header user={user || {}} />
                <NotFound />
              </>
            }
          />
          <Route
            path="/funnel/:propertyId"
            element={
              <>
                <Header user={user || {}} color={false} funnel />
                <TypeForm hideContact={false} />
              </>
            }
          />
          <Route
            path="/funnel/:propertyId/hc"
            element={
              <>
                <Header user={user || {}} color={false} funnel />
                <TypeForm hideContact />
              </>
            }
          />
          <Route
            path="/:userId/:propertyId"
            element={
              <>
                <Header user={user || {}} />{' '}
                <Property user={user} currentPage={1} />
              </>
            }
          />
          <Route
            path="/:userId/"
            element={
              <>
                <Header user={user || {}} /> <Home />
              </>
            }
          />
          <Route
            path="/*"
            element={
              <>
                <Header user={user || {}} />
                <NotFound />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

App.propTypes = {
  generalLoading: bool.isRequired,
  user: object,
  actions: any.isRequired,
};

App.defaultProps = {
  user: {},
};

const mapStateToProps = (state) => {
  const { error, generalLoading } = state.movement;
  const { user } = state.user;

  return {
    error,
    generalLoading,
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(reduxActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
