import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleButton } from 'react-bootstrap';
import { bool, func, object } from 'prop-types';
import { numFormat } from '../../helpers/input/format';

const ReadInformation = ({
  nextTo,
  setContacByWhatsApp,
  property,
  hideContact,
  openWhatsApp,
}) => {
  // const [next, setNext] = useState(false);
  const [radioValue, setRadioValue] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);
  const { t } = useTranslation();
  const itemForSelect = [
    {
      name: 'Sí',
      value: 'YES',
      event: () => {
        setContacByWhatsApp(true);
        if (hideContact) {
          setDisabledButton(true);
          openWhatsApp();
        } else {
          nextTo('READ_THE_INFORMATION', false);
          nextTo('NAME', true);
        }
      },
    },
    // {
    //   name: 'No',
    //   value: 'NO',
    //   event: () => {
    //     setContacByWhatsApp(false);
    //     nextTo('READ_THE_INFORMATION', false);
    //     nextTo('NAME', true);
    //   },
    // },
  ];

  const capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1);
  };

  return (
    <div className="container-center">
      <div>
        <div>
          <span style={{ fontSize: 20, fontWeight: 'bold' }}>
            {capitalize(t(property.type).toLocaleLowerCase())} en{' '}
            {t(property.neighborhood_name)}
          </span>
          <br />
          <span style={{ fontSize: 20 }}>
            <br />- Precio Venta: ${numFormat(property.price)}
            <br />- {property.area} m2
            <br />- {property.bedrooms} Habitaciones
            <br />- {property.bathrooms} Baños
            <br />- {property.parking} Parqueadero
            <br />- Año Construido: {property.year}
          </span>
          <br />
          <br />
          <span style={{ fontSize: 20, fontWeight: 'bold' }}>
            ¿Deseas chatear con el agente que está promocionando este inmueble?*
          </span>
          <br />
          <br />
        </div>
        <div>
          {itemForSelect.map((radio) => {
            return (
              <ToggleButton
                key={radio.value}
                type="radio"
                variant="outline-success"
                name="radio"
                value={radio.value}
                disabled={disabledButton}
                className="buttonItem"
                checked={radioValue === radio.value}
                onClick={() => {
                  window.fbq('track', 'FormFirstInteraction');
                  setRadioValue(radio.value);
                  radio.event();
                }}
              >
                {radio.name}
              </ToggleButton>
            );
          })}
        </div>
      </div>
      {/* {radioValue ? (
        <Button
          className="buttonNext"
          variant="success"
          onClick={() => {
            nextTo('READ_THE_INFORMATION', false);
            nextTo('NAME', true);
          }}
        >
          Aceptar{' '}
        </Button>
      ) : null} */}
    </div>
  );
};

ReadInformation.propTypes = {
  nextTo: func,
  setContacByWhatsApp: func,
  property: object,
  hideContact: bool,
  openWhatsApp: func,
};
ReadInformation.defaultProps = {
  nextTo: () => {},
  setContacByWhatsApp: () => {},
  openWhatsApp: () => {},
  property: null,
  hideContact: false,
};

export default ReadInformation;
