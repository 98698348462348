import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import { bool, any, object } from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as reduxActions from '../../redux/actions';
import './typeForm.scss';
import Loading from '../../components/loading/loading';
// import ContactWhatsApp from './contactWhatsApp';
import ReadInformation from './readInformation';
import { sendInformationCustomer } from '../../redux/services/property.service';

const TypeForm = ({
  generalLoading,
  actions,
  propertyDetail,
  user,
  hideContact,
}) => {
  const [property, setProperty] = useState(null);
  const [hideContactParam, setHideContactParam] = useState(false);
  const [name, setName] = useState('');
  const [numberPhone, setNumberPhone] = useState(null);
  const [loading, setLoading] = useState(false);
  const [contacByWhatsApp, setContacByWhatsApp] = useState(false);
  const [steps, setSteps] = useState({
    READ_THE_INFORMATION: true,
    NAME: false,
    WHATSAPP: false,
  });

  const [searchParams] = useSearchParams();

  // const [client, setClient] = useState({});
  const { propertyId } = useParams();

  useEffect(() => {
    actions.getPropertyByid(propertyId);
    document.title = 'Agenda Una Visita Ahora!';
  }, []);

  useEffect(() => {
    if (hideContact || searchParams.get('hidecontact')) {
      setSteps({
        READ_THE_INFORMATION: true,
      });
      setHideContactParam(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (propertyDetail != null) {
      setProperty(propertyDetail.property);
      const userProperty = propertyDetail.property.user;
      actions.getUser(userProperty.id);
      window.fbq('init', userProperty.pixel_id);
      window.fbq('track', 'PageView');
    }
  }, [propertyDetail]);

  const handlerShowStep = (key, step) => {
    const newSteps = steps;
    newSteps[key] = step;
    setSteps({ ...newSteps });
  };

  const openWhatsApp = async () => {
    setLoading(true);
    const userProperty = propertyDetail.property.user;
    window.fbq('track', 'Lead');
    await sendInformationCustomer(
      {
        user: userProperty.id,
        property: property.id,
        customer_number: numberPhone,
        customer_name: name,
        contac_by_whats_app: contacByWhatsApp,
        read_the_information: true,
      },
      name,
      property,
      user
    );
  };

  return generalLoading || !property ? (
    <Loading />
  ) : (
    <div className="container full-height">
      {/* {steps.CONTACT_WHATSAPP ? (
        <ContactWhatsApp
          show={steps}
          nextTo={handlerShowStep}
          setContacByWhatsApp={setContacByWhatsApp}
        />
      ) : null} */}
      {steps.READ_THE_INFORMATION ? (
        <ReadInformation
          property={property}
          show={steps}
          nextTo={handlerShowStep}
          setContacByWhatsApp={setContacByWhatsApp}
          openWhatsApp={openWhatsApp}
          hideContact={hideContactParam}
        />
      ) : null}
      {steps.NAME ? (
        <div className="container-center">
          <div className="sub-container">
            <span
              className="name-title"
              style={{ fontSize: 24, fontWeight: 'bold' }}
            >
              ¿Cual es tu nombre?*
            </span>
            <Form.Control
              type="input"
              className="form-control-white"
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #203346',
              }}
              onChange={(event) => {
                setName(event.target.value);
              }}
              placeholder="Escribe aquí tu respuesta..."
            />
            <Button
              className="buttonNext"
              variant="success"
              onClick={() => {
                handlerShowStep('NAME', false);
                handlerShowStep('WHATSAPP', true);
              }}
              disabled={!name}
            >
              Aceptar
            </Button>
          </div>
        </div>
      ) : null}
      {steps.WHATSAPP ? (
        <div className="container-center">
          <div className="sub-container">
            <span
              className="name-title"
              style={{ fontSize: 24, fontWeight: 'bold' }}
            >
              ¿Cual es tu WhatsApp?*
            </span>
            <Form.Control
              type="number"
              className="form-control-white"
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #203346',
              }}
              onChange={(event) => {
                setNumberPhone(event.target.value);
              }}
              placeholder="321 1234567"
            />
            <Button
              className="buttonNext"
              variant="success"
              onClick={openWhatsApp}
              disabled={!numberPhone || loading}
            >
              Aceptar{loading && '...'}
            </Button>
          </div>
        </div>
      ) : null}
      {steps.ENDWHATSAPP ? (
        <div className="container-center">
          <div>
            <span style={{ fontSize: 24, fontWeight: 'bold' }}>
              {' '}
              Por favor, indícame tu nombre y WhatsApp
            </span>
            <p>
              ¡Tu información es clave para mi! Me ayuda a saber quién eres, por
              si acaso no te puedo responder de inmediato.
            </p>

            <Form.Label htmlFor="exampleColorInput">Nombre</Form.Label>
            <Form.Control
              type="input"
              className="form-control-white"
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #203346',
              }}
              onChange={(event) => {
                setName(event.target.value);
              }}
              placeholder="Ingrese tu nombre"
            />
            <Form.Label htmlFor="exampleColorInput">
              Número de telefono
            </Form.Label>
            <Form.Control
              type="number"
              className="form-control-white"
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #203346',
              }}
              onChange={(event) => {
                setNumberPhone(event.target.value);
              }}
              placeholder="Ingrese tu numero telefonico"
            />
            <Button
              className="buttonNext"
              variant="success"
              onClick={openWhatsApp}
              disabled={!name || !numberPhone}
            >
              Aceptar{' '}
            </Button>
          </div>
        </div>
      ) : null}
      <div className="water-mark">
        <a
          rel="noopener noreferrer"
          href="http://funnels.lobbieapp.com/entrenamiento"
          target="_blank"
        >
          Creado con Lobbie Funnels
        </a>
      </div>
    </div>
  );
};

TypeForm.propTypes = {
  generalLoading: bool.isRequired,
  hideContact: bool.isRequired,
  actions: any.isRequired,
  propertyDetail: object,
  user: object,
};
TypeForm.defaultProps = {
  propertyDetail: null,
  user: null,
};

const mapStateToProps = (state) => {
  const { error, generalLoading } = state.movement;

  const {
    properties: { propertyDetail },
    user,
  } = state || [];

  return {
    error,
    generalLoading,
    propertyDetail,
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(reduxActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TypeForm);
